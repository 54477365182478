import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, Button, Divider, message } from "antd";
import axios from "../util/Api";
import firebase from "../util/firebase";

class EmailVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      viewEmailVerification: true
    };
  }

  onToggleEmailVerification = () =>{
    this.setState({
      viewEmailVerification: !this.state.viewEmailVerification
    })
  }

  success = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.success("A verification link has been sent to your email. Thank you.", 2.5));
  };

  fail = (errorMessage) => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.error(errorMessage, 2.5));
  };

  handleFormSubmit = values => {

    this.setState({
      loadingButton: true
    })

    axios.get(`api/check_user_email/?email=${values.email}`,{
      // headers: {
      //   'Authorization': `Token ${this.props.userToken}`
      // }
    })
    .then(res=>{
      if(res.data.message == true){
         //VERIFY EMAIL SEND LINK
         var user = firebase.auth().currentUser;
         var emailSendCheck = false;

         user.sendEmailVerification().then(function() {
           emailSendCheck = true
         }).catch(function(error) {
           emailSendCheck = false
         });

         setTimeout(() => {
           if(emailSendCheck == true){
              this.onToggleEmailVerification();
              this.setState({
                loadingButton: false
              })
              //FIREBASE SIGN OUT
              firebase.auth().signOut().then(function() {
              })
              .catch(function(error) {
              });
              this.success();
             setTimeout(() => {
              window.location.reload()
             }, 4000)
           }
           else{
             this.fail("Oops! Invalid form. Please try again.")
             this.setState({
              loadingButton: false
            })
           }
         }, 2000);

      }
      else{
        this.fail("Your Email Address was not found on the system. Please contact your supervisor.");
        this.setState({
          loadingButton: false
        })
      }
    })
    .catch(res=>{
      this.fail("Oops! Invalid form. Please try again.")
      this.setState({
        loadingButton: false
      })
      }
    )
  };

  render() {
    
    return (
      <Modal
        closable={false}
        title="Email Verification"
        visible={this.state.viewEmailVerification}
        footer={null}
        destroyOnClose
      >
        <Form onFinish={this.handleFormSubmit}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid Email Address!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email Address!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div layout="inline" className="gx-text-right">
            <Button
              key="reset"
              type="danger"
              ghost
              size="small"
              onClick={this.onToggleEmailVerification}
              ghost
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary" size="small" 
              loading={this.state.loadingButton}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default EmailVerification;
